import React from "react"
import PostLink from "../components/blog/templates/postLink";
import {Header} from "../components/header";
import {Footer} from "../components/landingpage/footer";
import "./blog.scss";
import {MetaTags} from "../components/metaTags";
import { graphql } from 'gatsby'

const title = "Blog de WealCome sur les meilleures pratiques de code";
const metaDescription = "Découvrez les meilleures pratiques de programmation " +
    "comme le TDD, le DDD et la Clean Architecture afin de devenir un meilleur codeur.";

const Blog = ({data}) => {
    const Posts = data.allMdx.edges
        .filter(edge => !!edge.node.frontmatter.date)
        .map(edge => <PostLink key={edge.node.id} post={edge.node}/>);
    return <div>
        <MetaTags title={title} description={metaDescription}/>
        <Header fixedTop={true} backgroundTransition={false}/>
        <section className="ptb-100">
            <div className="main container blog">
                <h3>Blog de WealCome</h3>
                <div className="blog-posts">
                    {Posts}
                </div>
            </div>
        </section>
        <Footer/>
    </div>;
};
export default Blog;

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            path
            title
            type
            description
          }
        }
      }
    }
  }
`;