import React from "react"
import {Link} from "gatsby";

const setPostTypeIconClassName = type => {
    switch (type) {
        case 'video':
            return 'lni lni-video icon video';
        case 'audio':
            return 'lni lni-souncloud-original icon audio';
        default:
            return 'lni lni-text-align-justify icon text';
    }
};

const PostLink = ({post}) => {
    const postTypeIconClassName = setPostTypeIconClassName(post.frontmatter.type);
    return (
        <div className="row justify-content-center card-row">
            <div className="col-10 col-sm-8 col-md-8 col-lg-8 card">
                <Link to={post.frontmatter.path}>
                    {/*<Img fluid={post.frontmatter.image.childImageSharp.fluid}
                     className="card-img-top" alt={post.frontmatter.title}/>*/}
                    <div className="card-body">
                        <h4 className="card-title"
                            dangerouslySetInnerHTML={{__html: post.frontmatter.title}}/>
                        <h6 className="card-subtitle mb-2 text-muted">
                            <i className="date">{post.frontmatter.date}</i>
                        </h6>
                        <i className={postTypeIconClassName}/>
                        <p className="card-text">{post.excerpt}</p>
                    </div>
                </Link>
            </div>
        </div>
    );
};
export default PostLink;